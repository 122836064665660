.users-container {
  width: 100%;
  margin: 30px 0;
  overflow: auto;
}

.cards {
  display: flex;
  flex-direction: row;
  /* justify-content: space-around; */
  flex-wrap: wrap;
  align-content: space-around;
  align-items: center;
  width: 1371px;
}

/* width */
.users-container::-webkit-scrollbar {
  height: 5px;
}

/* Track */
.users-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.users-container::-webkit-scrollbar-thumb {
  background: #cebda9;
}

/* Handle on hover */
.users-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.users-title {
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  color: #4c443f;
  margin: 4px;
}

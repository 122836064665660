.videoWrapper {
  display: flex;
  padding: 20px 45px 0 10px;
}
.newVideoWrapper {
  display: flex;
  padding: 20px 20px 5px 20px;
  position: relative;
}
.videoInfo {
  width: 90%;
  margin-left: 20px;
  font-size: 12px;
  line-height: 12px;
  color: #4c443f;
}
.inputVideoWrapperWidth {
  width: calc(100% - 45px);
}
.videoLevelOne {
  background: #faf9f3;
  color: inherit;
  display: flex;
  align-items: center;
  padding: 5px 0 !important;
}
.videoLevelOne .accordion-button {
  font-size: 13px;
  background: #faf9f3;
}
.videoLevelOne + .accordion-collapse {
  position: relative;
  overflow: hidden;
}
.videoLevelOne + .accordion-collapse::before,
.videoLevelOne + .accordion-collapse::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 18px;
  left: 0;
  z-index: 1;
  pointer-events: none;
}
.videoLevelOne + .accordion-collapse::before {
  box-shadow: 0px 5px 15px rgba(208, 205, 187, 0.6);
  margin-top: -18px;
  top: 0;
}
.videoLevelOne + .accordion-collapse::after {
  box-shadow: 0px -5px 15px rgba(208, 205, 187, 0.6);
  margin-bottom: -18px;
  bottom: 0;
}
.videoLevelOne + .accordion-collapse .actionMoment {
  width: 120px;
  background: transparent;
  padding: 0 !important;
} 
.VideoLevelTwo .card-header {
  border: none;
  display: flex;
  background: #fffefc;
  align-items: center;
}
.VideoLevelTwo {
  padding: 14px 0;
}
.VideoLevelTwo * {
  background: #fffefc;
}
.setIconPosition {
  position: absolute;
  right: 0;
  top: 7px;
}
.borderBottom {
  border-bottom: 1px solid #e8e6e4;
}
.MuiButton-text {
  border: 1px solid #ebe9dd !important;
}
@media only screen and (max-width: 600px) {
  .videoWrapper,
  newVideoWrapper {
    display: block;
    padding: 25px;
  }
  .videoInfo {
    margin-left: 0px;
    margin-top: 10px;
  }
}

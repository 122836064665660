ul {
  list-style-type: none;
}
a {
  text-decoration: none;
  color: #c7922c;
}
a:hover {
  color: #c7922c;
}
.container-nav {
  background-color: transparent;
  width: 1400px;
  height: 50px;
  left: 250px;
  top: 31px;
  margin-bottom: 30px;
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
}

.subcontainer-nav {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.links-nav {
  width: 900px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.portrait-nav {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.inactive-nav {
  color: #c7922c;
}
.navLink {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.triangle {
  display: none;
  position: relative;
  width: 9px;
  height: 9px;
  margin: 0 auto;
  bottom: 10px;
}

.active-nav {
  color: #4c443f;
  position: relative;
}
.active-nav:hover {
  color: #4c443f;
}
.portrait {
  display: flex;
}
.portrait-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 10px;
  color: #4c443f;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
.portrait-pic {
  width: 45px;
  height: 45px;
}

.me-auto {
  margin: 0 auto;
}
.me-auto a {
  padding: 0 15px;
  font-family: Montserrat, serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
}
a.active-nav:before {
  content: '';
  background-image: url('../../assets/icons/activeNav.svg');
  height: 14px;
  width: 14px;
  position: absolute;
  top: -14px;
  left: 44%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 12px;
}

@media only screen and (min-width: 1250px) {
  .container {
    max-width: 1200px;
  }
}
@media only screen and (min-width: 1380px) {
  .navbarFlex {
    display: flex !important;
  }
  .me-auto a {
    padding: 0 20px;
  }
  .container {
    max-width: 1395px;
  }
}

@media only screen and (max-width: 1199px) {
  .me-auto a {
    padding: 5px 0;
  }
}

.workoutCollapse {
  .workoutCollapseHeader {
    padding: 20px;
    border-bottom: 1px solid #ebe9dd;
    .headerInput {
      label {
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        text-transform: uppercase;
        color: #4c443f;
      }
    }
  }

  .workoutCollapseTable {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    .workoutTableTitle {
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      text-transform: uppercase;
      color: #aa957f;
      border-bottom: 1px solid #ebe9dd;
      td {
        padding: 10px;
      }
      td:nth-child(1) {
        padding-left: 20px;
      }
    }
  }
}

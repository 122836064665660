.location-container {
  background: #faf9f3;
  border-radius: 5px;
  width: 1400px;
  padding: 15px;
  margin-bottom: 20px;
}

.location-directory {
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
}
.color-link {
  color: #62a8a4;
}
.breadcrumb-container {
  background: #faf9f3;
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.data-subNav {
  font-style: normal;
  font-weight: 200;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;

  color: #4c443f;
}

.userMessageWrapper {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  width: 100%;

  .userInfoWrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    img {
      border-radius: 50%;
      object-fit: cover;
      margin-right: 10px;
    }
    .userName {
      margin-right: 10px;
      font-size: 12px;
      line-height: 15px;
      color: #4c443f;
    }
    .actionLink {
      font-weight: bold;
      font-size: 9px;
      line-height: 11px;
      text-transform: uppercase;
      color: #62a8a4;
    }
  }
  .userOpen {
    background: #faf9f3;
    color: inherit;
    display: flex;
    align-items: center;
    // padding: 5px 15px 5px 0 !important;
    justify-content: space-between;

    /* .accordion-button {
      font-size: 13px;
      background: #faf9f3;
    } */
  }
}

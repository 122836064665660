@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');
/*@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@1,500&display=swap');*/
.App-content {
  background: rgb(226, 222, 219) url(./assets/images/dashboard-bg.png);
  padding: 0 12px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 14px;
  font-family: Montserrat, serif;
  color: #4c443f;
}
.margin {
  margin: 0;
}
.tabAddBtn {
  background: #f4f3ee !important;
}
.tabDeleteBtn {
  background: #f4f3ee !important;
}
.MuiButton-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}
.addActionUi .tab-content ul li:nth-child(2) {
  margin-left: inherit !important;
}
.addActionUi .tab-content ul li:last-child {
  margin-left: auto;
}
.fullPageLoader {
  min-height: 70vh;
  display: grid;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.flexibleLoader {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 9999;
  background-color: #faf9f380;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.borderRight {
  border-right: 2px solid #e8e6e4;
}
.card:nth-of-type(1) {
  border: 5px solid;
}
.dropdown {
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  color: #4c443f;
  background: url('./assets/icons/selectIcon.png') no-repeat right;
  background-position-x: 97%;
  padding: 10px;
  border: 1px solid #ebe9dd !important;
}
.whiteBtn {
  background: #fff !important;
  color: #62a8a4 !important;
  font-size: 10px !important;
  font-weight: 600 !important;
}
.borderBottom {
  border-bottom: 1px solid #efedeb;
}
.accordion-button {
  font-size: 14px;
  padding: 14px 7px 14px 20px;
}
.form-control,
.form-control-select {
  background-color: #fffefc;
  border: 1px solid #ebe9dd;
  box-sizing: border-box;
  border-radius: 5px;
  color: #4c443f;
  font-size: 11px;
  line-height: 20px;
}
.form-control:focus,
.form-control-select:focus {
  box-shadow: 0 0 7px 0.1rem #f4f3ee;
  border: 1px solid #ebe9dd;
}
.align-item-center {
  align-items: center;
}
.accordion-button:not(.collapsed) {
  background: #faf9f3;
  color: inherit;
}
.accordion-button:not(.collapsed)::after {
  transform: rotate(0deg);
}
.accordion-item {
  position: relative;
}
.accordion-button::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'><path d='M2.63398 5.5C3.01888 6.16667 3.98113 6.16667 4.36603 5.5L6.53109 1.75C6.91599 1.08333 6.43486 0.25 5.66506 0.25L1.33493 0.25C0.565135 0.25 0.084011 1.08333 0.468911 1.75L2.63398 5.5Z' fill='%23AA957F'/></svg>") !important;
  height: 9px !important;
  width: 9px !important;
  transform: rotate(-90deg);
  position: absolute;
  left: 10px;
}
.off .accordion-button::after {
  transform: rotate(0deg);
}
.off.accordion-header button {
  font-weight: 700;
}
.accordion-header button {
  font-weight: normal;
  color: #4c443f;
}
.accordion-button:focus {
  box-shadow: none;
}
.accordion-button {
  box-shadow: 0px 5px 15px rgba(208, 205, 187, 0.6) !important;
}
button.accordion-button.collapsed {
  box-shadow: none !important;
}
.relative {
  position: relative;
}
.ansDots {
  position: absolute;
  right: 0;
  top: 6px;
}
.menuDots {
  position: absolute;
  top: 10px;
  right: 9px;
  z-index: 5;
}
.menuDots button {
  background: none !important;
  border: none;
}
.menuDots .dropdown-toggle::before {
  border: none !important;
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3 13"><path d="M1.5 3C0.671573 3 -1.01779e-07 2.32843 -6.55671e-08 1.5C-2.93554e-08 0.671573 0.671573 -1.01779e-07 1.5 -6.55671e-08C2.32843 -2.93554e-08 3 0.671573 3 1.5C3 2.32843 2.32843 3 1.5 3Z" fill="%23AA957F"/><path d="M1.5 8C0.671573 8 -3.20336e-07 7.32843 -2.84124e-07 6.5C-2.47912e-07 5.67157 0.671573 5 1.5 5C2.32843 5 3 5.67157 3 6.5C3 7.32843 2.32843 8 1.5 8Z" fill="%23AA957F"/><path d="M1.5 13C0.671572 13 -5.38893e-07 12.3284 -5.02681e-07 11.5C-4.66469e-07 10.6716 0.671572 10 1.5 10C2.32843 10 3 10.6716 3 11.5C3 12.3284 2.32843 13 1.5 13Z" fill="%23AA957F"/></svg>') !important;
  height: 13px !important;
  width: 3px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
label.form-label {
  font-size: 11px;
  line-height: 12px;
  color: #4c443f;
  display: block;
  padding-bottom: 6px;
  padding-left: 3px;
}
.menuDots button:focus {
  box-shadow: none !important;
}
.dropdown-item {
  font-size: 12px;
}

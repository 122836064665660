.nutritionTableRow {
  .nutritionName {
    display: flex;
    align-items: center;

    span {
      font-size: 12px;
      line-height: 15px;
      color: #4c443f;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  td {
    padding: 10px;

    .inputTarget {
      max-width: 160px;
    }
  }
  td:nth-child(1) {
    padding-left: 20px;
  }
  .smallText {
    font-weight: 600;
    font-size: 9px;
    line-height: 11px;
    text-transform: uppercase;
    color: #62a8a4;
    width: 70px;
    padding-left: 5px;
    cursor: pointer;
  }
  .card {
    background-color: #fffefc;
  }
}

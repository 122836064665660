.userMessageListWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .searchInputWrapper {
    .inputBoxWrapper {
      position: relative;
      .iconAction {
        position: absolute;
        top: 11px;
        right: 20px;
        padding: 5px !important;
        margin: 0;
        min-width: auto;
      }
    }
  }

  .userList {
    overflow-y: auto;
    flex: 1 1;
  }

  .loadMoreBtn {
    margin: 0;
    font-weight: 600;
    font-size: 10px;
    line-height: 11px;
    text-transform: uppercase;
    color: #62a8a4;
    background: #fff;
    cursor: pointer;
    transition: 0.5s;

    &:hover {
      background: #f4f3ee;
    }
  }
}

.mainLevelWrapper {
  background: #faf9f3;

  .topInputs {
    padding: 12px 0 0;
    display: flex;
    .workoutInput {
      max-width: 220px;
      width: 100%;
      position: relative;
      margin: 0 10px;

      label {
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        text-transform: uppercase;
        color: #cebda9;
        position: absolute;
        top: 0;
        left: 15px;
        height: 100%;
        margin: 0;
        display: flex;
        align-items: center;
        width: 68%;
      }
      input {
        text-align: right;
      }
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type='number'] {
        -moz-appearance: textfield;
      }
    }
  }
  .workoutAccordion {
    .accordionWorkout {
      background: #fffefc;
      display: flex;
      align-items: center;
      padding: 0px 16px;
      .accordion-button {
        font-size: 12px !important;
        line-height: 15px;
        color: #4c443f !important;
      }
    }
  }
}

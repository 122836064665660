.exercisesTrackedWrapper {
  padding: 15px 20px;
  display: flex;
  align-items: center;
  background: #fffefc;
  border-bottom: 1px solid #e8e6e4;
  .chart {
    position: relative;
    span {
      position: absolute;
    }
  }
  .nameChart {
    padding-left: 10px;
  }
}

.userInformationWrapper {
  background: #fffefc;

  .infoWrapper {
    padding: 20px;
    display: flex;
    border-bottom: 1px solid #f4f3ee;
    .profileImage {
      width: 140px;
      height: 140px;
      border-radius: 50%;
      //background: lightgrey;
    }
    .profileContent {
      padding-left: 20px;
      .userName {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #4c443f;
      }
      .category {
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        color: #4c443f;
        margin: 9px 0 15px;
      }
      .categoryInfo {
        .title {
          font-size: 12px;
          line-height: 15px;
          color: #4c443f;
          margin-left: 20px;
        }
      }
    }
  }

  .userInfoFormWrapper {
    display: flex;
    width: 100%;
    padding: 20px;

    .row {
      margin-bottom: 20px;
      :last-child {
        margin-bottom: 0;
      }
    }
    .cancelBtn {
      background: #f5c5c5;
      color: #aa7f7f;
    }

    .links {
      font-size: 9px;
      line-height: 11px;
      text-transform: uppercase;
      font-weight: 600;
      color: #62a8a4;
      display: flex;
      justify-content: space-between;

      .col {
        padding: 0;
        width: 100%;
      }
    }
    form {
      width: 100%;
    }
  }
}

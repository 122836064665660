.exerciseRow {
  .exerciseName {
    display: flex;
    align-items: center;
    .form-check-input {
      opacity: 0;
    }

    label {
      font-size: 12px;
      line-height: 15px;
      color: #4c443f;
      position: relative;
      padding-left: 5px;
      top: 1px;
    }
    img {
      width: 15px;
    }
    span {
      font-size: 12px;
      line-height: 15px;
      color: #4c443f;
      position: relative;
      padding-left: 5px;
    }
  }
  td {
    padding: 10px;

    .dropdown.form-select {
      font-size: 11px;
      line-height: 13px;
      display: flex;
      align-items: center;
      color: #4c443f;
      max-width: 110px;
      background: url('../../../../../assets/icons/selectIcon.png') no-repeat right;
      background-position-x: 92%;
      padding: 10px;
      border: 1px solid #ebe9dd !important;
    }
  }
  td:nth-child(1) {
    padding-left: 20px;
    max-width: 115px;
  }
  .smallText {
    font-weight: 600;
    font-size: 9px;
    line-height: 11px;
    text-transform: uppercase;
    color: #62a8a4;
    width: 70px;
    padding-left: 5px;
    cursor: pointer;
  }
  .card {
    background-color: #fffefc;
  }
}
.form-select:focus {
  box-shadow: 0 0 0 0.25rem #efedeb8c !important;
}
.card {
  background-color: #fffefc;
}

:focus {
  outline: none !important;
}
:focus-visible {
  outline: none !important;
}
.titleTab {
  border-bottom: 0 !important;
  background: #fffefc;
  padding: 16px 10px 0;

  .nav-item {
    padding: 0 6px;

    .nav-link {
      font-weight: 600;
      font-size: 10px;
      line-height: 11px;
      text-transform: uppercase;
      color: #62a8a4;
      border: none !important;
      position: relative;
      padding: 0 0 15px !important;
      margin: 0 10px;
    }
    .nav-link:hover {
      border: none !important;
    }
    .nav-link.active {
      background: transparent !important;
      border: none !important;
      color: #4c443f !important;
    }
    .nav-link.active:before {
      content: '';
      width: 100%;
      height: 2px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: #4c443f;
    }
  }
}
.planLevelOne {
  background: #faf9f3;
  color: inherit;
  display: flex;
  align-items: center;
  padding: 5px 0 !important;
  justify-content: space-between;
  padding-right: 22px !important;

  .accordion-button {
    font-size: 13px;
    background: #faf9f3;
  }
}

.right20 {
  right: 22px !important;
}
.level {
  background: #fffefc;
  display: flex;
  justify-content: space-between;
  padding: 15px 22px;
}
.countUser {
  color: #4c443f;
  font-size: 12px;
  line-height: 15px;
}
.dropDownPlan {
  background: #fffefc;
  border: 1px solid #ebe9dd;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 8px 10px;
  margin-right: 5px;
}
.questionText {
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: #62a8a4;
  padding-right: 6px;
  width: 230px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.dropdown-item:active {
  background-color: #62a8a4 !important;
}
.questionAns {
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  color: #4c443f;
  padding: 0 5px;
}
.popover-arrow {
  opacity: 0;
}
div#popover-positioned-bottom {
  top: -7px !important;
  background: #fffefc !important;
  border: 1px solid #ebe9dd !important;
  border-radius: 5px !important;

  .plansOptions {
    span {
      padding: 2px 5px 3px;
      margin: 0 2px;
      border-radius: 3px;
      cursor: pointer;
    }
  }
  .plansOptions span.active {
    background: #4c443f;
    color: #fff;
  }
}
.descriptionBox {
  padding: 20px;
  border-top: 1px solid #cebda9;
  p {
    font-size: 11px;
    line-height: 12px;
    color: #4c443f;
    padding: 4px;
    margin-bottom: 5px;
  }
}

.popover-body {
  padding: 0 10px;
}
.planText {
  color: #4c443f;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  display: flex;
  margin: 15px 0;
}
.textDark {
  font-weight: 700;
  padding-right: 20px;
}

.plansManagementWrapper {
  background: #faf9f3;
  .nutritionAccordion {
    .accordionWorkout {
      background: #F4F3EE;
      display: flex;
      align-items: center;
      padding: 0 16px;
      position: relative;
      z-index: 1;
      .accordion-button {
        font-size: 12px !important;
        line-height: 15px;
        color: #4c443f !important;
        text-transform: inherit;
        background: transparent !important;
      }
    }
    .accordion-collapse {
      position: relative;

      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 18px;
        left: 0;
      }

      &::before {
        box-shadow: 0px 5px 15px rgba(208, 205, 187, 0.6);
        margin-top: -18px;
        top: 0;
      }

      &::after {
        box-shadow: 0px -5px 15px rgba(208, 205, 187, 0.6);
        margin-bottom: -18px;
        bottom: 0;
      }
    }
    .borderBottom-buttons {
      position: relative;
      background-color: #f4f3ee;
    }
  }
}

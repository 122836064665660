

.chatWrapper {
  overflow-y: auto;
  position: relative;
  flex: 1;
}

.chatMessageWrapper {
  margin-top: 25px;
  padding-right: 20px;

  .sendWrapper,
  .receiveWrapper {
    display: flex;
    margin-bottom: 15px;
    .userProfile {
      img {
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .message {
      .search-result {
        background-color: yellow;
      }
    }
  }
  .receiveUser {
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 8px;
  }
  .receiveWrapper {
    .messageWrapper {
      margin-left: 10px;
      .message {
        text-align: left;
      }
    }
  }
  .sendWrapper {
    .messageWrapper {
      margin-right: 10px;
      .message {
        text-align: right;
      }
    }
  }
  .messageWrapper {
    width: 100%;

    .message {
      background: #f4f3ee;
      border-radius: 5px;
      padding: 8px 15px;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.01em;
      color: #4c443f;
    }

    .date {
      font-weight: 600;
      font-size: 9px;
      line-height: 30px;
      letter-spacing: 0.01em;
      color: #cbc3b9;
    }
  }

  .userProfile {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    object-fit: contain;
    margin-bottom: 30px;
  }
}
.searchInputWrapper {
  .inputBoxWrapper {
    position: relative;

    .iconAction {
      position: absolute;
      top: 19px;
      right: 8px;
      padding: 5px !important;
      margin: 0;
      min-width: auto;
      background-color: transparent !important;
    }
  }
}
.loadMoreBtn {
  margin: 0;
  font-weight: 600;
  font-size: 10px;
  line-height: 11px;
  text-transform: uppercase;
  color: #62a8a4;
  background: #fff;
  cursor: pointer;
  transition: 0.5s;
  padding-bottom: 20px;
}
.sendMessageWrapper {
  display: flex;
  align-items: center;

  button {
    background: transparent;
  }

  .inputWrapper {
    width: 100%;
    margin-right: 20px;
  }
  textarea.form-control {
    margin-top: 4px;
  }
  span {
    font-size: 10px;
    line-height: 12px;
    color: #4c443f;
    margin-bottom: 5px;
  }
  .pointer {
    cursor: pointer;
  }
}

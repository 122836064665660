.userProfileRightContent {
  border-radius: 10px;
  overflow: hidden;
  .tabList {
    :first-child {
      border-right: 2px solid #e8e6e4;
    }
    .nav-item {
      width: 50%;
    }
    .nav-link {
      width: 100% !important;
      background: #faf9f3;
      color: #62a8a4;
      font-weight: 700;
      font-size: 11px;
      line-height: 11px;
      text-transform: uppercase;
      padding: 15px 0 14px;
      border: none;
    }
    .nav-link.active {
      background: #fffefc;
      color: #4c443f;
    }
    button {
      border-radius: unset;
    }
  }
}

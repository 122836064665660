.nutritionPlanFormWrapper {
  padding: 20px;
  label {
    font-size: 11px;
    line-height: 12px;
    color: #4c443f;
  }
}
.nutritionPlanTableWrapper {
  border-top: 1px solid #ebe9dd;
  .workoutTableTitle {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: #aa957f;
    border-bottom: 1px solid #ebe9dd;
    td {
      padding: 10px;
    }
    td:nth-child(1) {
      padding-left: 20px;
    }
  }

  .nutritionPlanTableRow {
    .nutritionName {
      display: flex;
      align-items: center;

      span {
        font-size: 12px;
        line-height: 15px;
        color: #4c443f;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    td {
      padding: 10px;

      .inputTarget {
        max-width: 160px;
      }
      .dropdown.form-select {
        font-size: 11px;
        line-height: 13px;
        display: flex;
        align-items: center;
        color: #4c443f;
        max-width: 160px;
        background: url('../../../../assets/icons/selectIcon.png') no-repeat right;
        background-position-x: 92%;
        padding: 10px;
        border: 1px solid #ebe9dd !important;
      }
    }
    td:nth-child(1) {
      padding-left: 20px;
    }
    .smallText {
      font-weight: 600;
      font-size: 9px;
      line-height: 11px;
      text-transform: uppercase;
      color: #62a8a4;
      width: 70px;
      padding-left: 5px;
      cursor: pointer;
    }
    .card {
      background-color: #fffefc;
    }
  }
}

.morningMomentsWrapper {
  background: #fffefc;

  .morningMomentFormWrapper {
    padding: 20px;
    // display: flex;
    // align-items: center;
    // flex-wrap: wrap;
    // flex-direction: row;
    // justify-content: space-between;
    /* .dateMoment {
      width: 29%;
      min-width: 200px;
    }
    .inputMoment {
      width: 54%;
      min-width: 200px;
    }*/
    .actionMoment {
      width: 78px;
      background: transparent;
      padding: 0 !important;
    } 
    .form-select {
      font-size: 11px;
      line-height: 13px;
      display: flex;
      align-items: center;
      color: #4c443f;
      background: url('../../../../assets/icons/selectIcon.png') no-repeat right;
      background-position-x: 92%;
      padding: 10px;
      border: 1px solid #ebe9dd !important;
    }
  }

  .momentTableWrapper {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    .tableTitle {
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      text-transform: uppercase;
      color: #aa957f;
      border-bottom: 1px solid #ebe9dd;
      td {
        padding: 10px 25px;
      }
      td:nth-child(1) {
        width: 105px;
      }
    }
    .tableData {
      border-top: 1px solid rgba(0, 0, 0, 0.125);
      position: relative;
      td {
        padding: 6px 20px;
        span {
          font-size: 12px;
          line-height: 15px;
          color: #4c443f;
        }
      }
      td:nth-child(1) {
        width: 105px;
      }

      img {
        width: 60px;
      }
    }
  }
}

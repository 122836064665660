button {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 5px;
  border: none;
  padding: 10px 0;
  position: relative;
}
.w-200 {
  width: 200px;
}
.spinner {
  width: 25px !important;
  height: 25px !important;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}
.cancelBtn {
  background: #ebe9e0;
  color: #aa957f;
}
.saveBtn {
  background: #62a8a4;
  color: #f4f3ee;
}

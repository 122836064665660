.nutritionPlansWrapper {
  background: #faf9f3;
  .nutritionAccordion {
    .accordionNutrition {
      background: #fffefc;
      display: flex;
      align-items: center;
      padding: 0px 16px;
      .accordion-button {
        font-size: 12px !important;
        line-height: 15px;
        color: #4c443f !important;
      }
    }
  }
}
.actionFooter {
  background: #fffefc;
  padding: 10px 20px;
  button {
    margin-right: 10px;
  }
}

.saveGoalsTable {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  .saveGoalsTitle {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    border-bottom: 1px solid #ebe9dd;
    td {
      padding: 10px;
      color: #aa957f;
    }
    td:nth-child(1) {
      padding-left: 20px;
    }
  }
  td {
    padding: 5px 20px;
    font-size: 12px;
    line-height: 15px;
    color: #4c443f;
  }
  tr {
    border-bottom: 1px solid #edebe3;
  }
  img {
    width: 28px;
  }
}

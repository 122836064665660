.timeWrapper {
  font-size: 9px;
  line-height: 11px;
  text-transform: uppercase;
  color: #4c443f;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  justify-content: space-between;
  border-bottom: 1px solid #ebe9dd;

  .timeWrapperText {
    display: flex;
    align-items: center;

    .daysTitle {
      min-width: 130px;
      padding-left: 10px;
    }
    .weekTitle {
      min-width: 90px;
      padding-left: 10px;
    }
    .monthTitle {
    }
  }
}
.datePickerWrapper {
  background: #faf9f3;
  border-radius: 10px;
  padding: 20px;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #3c3b36;
  max-width: 330px;
  margin: 0 auto;
  .boxWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px;
    justify-content: center;
    div {
      border: 1px solid transparent;
      padding: 5px;
      width: 33.33%;
      border-bottom: 1px solid #ebe9e0;
      span {
        border-radius: 50%;
        width: 45px;
        height: 45px;
        align-items: center;
        display: flex;
        margin: 0 auto;
        padding: 0;
        text-align: center;
        justify-content: center;
        cursor: pointer;
      }
      span.activeDate {
        background: #4c443f;
        color: #fff;
      }
    }
    div:nth-child(2),
    div:nth-child(5),
    div:nth-child(7) {
      border-left: 1px solid #ebe9e0;
      border-right: 1px solid #ebe9e0;
    }
    div:nth-child(7) {
      border-bottom: 0;
    }
  }
  .footerDate {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #c7922c;
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    flex-wrap: wrap;
    span {
      cursor: pointer;
    }
    span:last-child {
      padding-left: 40px;
    }
  }
}

.weekPickerWrapper {
  background: #faf9f3;
  border-radius: 10px;
  padding: 20px;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #3c3b36;
  max-width: 330px;
  margin: 0 auto;
  .boxWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px;
    justify-content: center;
    div {
      border: 1px solid transparent;
      padding: 5px;
      width: 33.33%;
      border-bottom: 1px solid #ebe9e0;
      span {
        border-radius: 50%;
        width: 45px;
        height: 45px;
        align-items: center;
        display: flex;
        margin: 0 auto;
        padding: 0;
        text-align: center;
        justify-content: center;
        cursor: pointer;
      }
      .upIcon {
        transform: rotate(180deg);
      }
      span.activeDate {
        background: #4c443f;
        color: #fff;
      }
    }
    div:nth-child(2),
    div:nth-child(5),
    div:nth-child(8),
    div:nth-child(11) {
      border-left: 1px solid #ebe9e0;
      border-right: 1px solid #ebe9e0;
    }
    div:nth-child(10),
    div:nth-child(11),
    div:nth-child(12) {
      border-bottom: 0;
    }
  }
  .footerDate {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #c7922c;
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    flex-wrap: wrap;
    span {
      cursor: pointer;
    }
    span:last-child {
      padding-left: 40px;
    }
  }
}

.monthPickerWrapper {
  background: #faf9f3;
  border-radius: 10px;
  padding: 20px;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #3c3b36;
  max-width: 330px;
  margin: 0 auto;
  .boxWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px;
    justify-content: center;
    div {
      border: 1px solid transparent;
      padding: 5px;
      width: 33.33%;
      border-bottom: 1px solid #ebe9e0;
      span {
        border-radius: 50%;
        width: 45px;
        height: 45px;
        align-items: center;
        display: flex;
        margin: 0 auto;
        padding: 0;
        text-align: center;
        justify-content: center;
        cursor: pointer;
      }
      span.activeDate {
        background: #4c443f;
        color: #fff;
      }
    }
    div:nth-child(2),
    div:nth-child(5),
    div:nth-child(8),
    div:nth-child(11) {
      border-left: 1px solid #ebe9e0;
      border-right: 1px solid #ebe9e0;
    }
    div:nth-child(10),
    div:nth-child(11),
    div:nth-child(12) {
      border-bottom: 0;
    }
  }
  .footerDate {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #c7922c;
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    flex-wrap: wrap;
    span {
      cursor: pointer;
    }
    span:last-child {
      padding-left: 40px;
    }
  }
}

.bgTransparent {
  background: transparent !important;
}

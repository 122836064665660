.userFeedbackWrapper {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #4c443f;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .feedbackWrapper {
    padding: 15px;
    border-bottom: 1px solid #f4f3ee;
    p {
      margin-bottom: 10px;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }

  .searchInputWrapper {
    .inputBoxWrapper {
      position: relative;
      .iconAction {
        position: absolute;
        top: 3px;
        right: 12px;
        padding: 5px !important;
        margin: 0;
        min-width: auto;
        border: none !important;
      }
    }
  }
}

.usersList {
  width: 1400px;
  height: 55px;
  border-radius: 10px 10px 0px 0px;
  background-color: #f4f3ee;
  padding: 15px;
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  color: #4c443f;
}
.usersList-header {
  padding: 15px;
}

.usersList-name {
  color: #62a8a4;
}
thead {
  border-radius: 10px 10px 0px 0px;
}
th {
  padding: 15px;
  background-color: #f4f3ee;
}

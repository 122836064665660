.chat-wrapper {
  overflow-y: auto;
  position: relative;
  flex: 1 1;
}
.chatMessageWrapper {
  margin-top: 25px;
  .sendWrapper,
  .receiveWrapper {
    display: flex;
    margin-bottom: 15px;
  }
  .receiveUser {
    font-size: 10px;
    line-height: 12px;
  }
  .receiveWrapper {
    .messageWrapper {
      margin-left: 10px;
      .message {
        text-align: left;
      }
    }
  }
  .sendWrapper {
    .messageWrapper {
      margin-right: 10px;
      .message {
        text-align: right;
      }
    }
  }
  .messageWrapper {
    width: 100%;

    .message {
      background: #f4f3ee;
      border-radius: 5px;
      padding: 10px 15px;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.01em;
      color: #4c443f;
    }

    .date {
      font-weight: 600;
      font-size: 9px;
      line-height: 30px;
      letter-spacing: 0.01em;
      color: #cbc3b9;
    }
  }

  .userProfile {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    object-fit: cover;
    margin-bottom: 30px;
  }
}
.sendMessageWrapper {
  display: flex;
  align-items: center;
  .inputWrapper {
    width: 100%;
    margin-right: 20px;
  }
  textarea.form-control {
    margin-top: 4px;
  }
  span {
    font-size: 10px;
    line-height: 12px;
    color: #4c443f;
    margin-bottom: 5px;
  }
  .pointer {
    cursor: pointer;
  }
}

.plansUsers {
  background: #f4f3ee;
  border-radius: 0;

  .nav-item {
    .nav-link {
      font-weight: 600;
      font-size: 10px;
      line-height: 11px;
      text-transform: uppercase;
      color: #62a8a4;
      border: none !important;
      position: relative;
      padding: 15px 25px;
      border-radius: 0;
      background: #faf9f3;
      margin: 1px 0;
    }

    .nav-link:hover {
      border: none !important;
    }

    .nav-link.active {
      background: #fffefc !important;
      border: none !important;
      color: #4c443f !important;
    }
  }
}

.currentProgress {
  background: #fffefc;

  .trackingActivityWrapper {
    border-bottom: 1px solid #f4f3ee;
    padding: 19px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      text-transform: uppercase;
      color: #4c443f;
    }
    .subtitle {
      font-weight: 700;
      font-size: 9px;
      line-height: 11px;
      text-align: right;
      text-transform: uppercase;
      color: #62a8a4;
    }
  }

  .activityProgressWrapper {
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    align-items: center;

    .activityTitle {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #4c443f;
      width: max-content;
      p {
        min-width: 125px;
        margin: 0;
      }
    }
    .progress {
      width: 96%;
      margin: 0 auto;
    }
    .progress-bar {
      color: #f4f3ee;
      background-color: #c7922c;
      border-radius: 5px;
    }
    .progress {
      height: 5px;
      background-color: #f4f3ee;
      border-radius: 20px;
    }
  }
  .bgColor {
    background: red;
  }
  .chartName {
    padding: 20px 20px 0;
    display: block;
  }
  line {
    stroke: #d9d7cf;
  }
  svg.recharts-surface {
    background: linear-gradient(45deg, #f3f2eb, #f3f2eb) no-repeat;
    background-position-y: 46px;
  }
  tspan {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
  }
}

.userLevelOne {
  background: #faf9f3;
  color: inherit;
  display: flex;
  align-items: center;
  padding: 5px 0 !important;
  justify-content: space-between;
  padding-right: 22px !important;

  .accordion-button {
    font-size: 12px;
    line-height: 15px;
    color: #4c443f;
    background: initial;
  }
}

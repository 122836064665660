.usersTable {
  width: 805px;
  height: 55px;
  border-radius: 10px 10px 0px 0px;
  background-color: #f4f3ee;
  padding: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  color: #4c443f;
}
.users-table-image {
  object-fit: cover;
  border-radius: 50%;
  margin-right: 8px;
}
.usersTable-header {
  display: flex;
  flex-direction: row;
}
.usersTable-dropdowns-title {
  font-size: 10px;
}
.usersTable-dropdowns {
  font-weight: 700;
  width: 170px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
}
.usersTable-select {
  background-color: transparent;
  color: #62a8a4;
  border: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  font-size: 10px;
  text-overflow: '';
  text-transform: uppercase;
  font-weight: 500;
}

.inlineTable {
  display: inline-table;
  width: 100%;
  background-color: #fffefc;
}
.groupTR {
  background: #ebe9dd;
  .groupList {
    td:nth-child(1) {
      padding-left: 20px;
      max-width: 130px;
    }
  }
}

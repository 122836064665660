.CancelBtn {
  background: #ebe9e0 !important;
}
.SaveBtn {
  background: #62a8a4 !important;
  color: #fff !important;
}
.SaveBtn:hover {
  background: #539c98 !important;
  color: #fff !important;
}
.min200 {
  min-width: 200px !important;
  font-size: 12px !important;
  margin-left: 10px !important;
  font-family: Montserrat !important;
}
.QuizLevelOne {
  background: #faf9f3;
  color: inherit;
  display: flex;
  align-items: center;
  padding: 5px 0 !important;
}
.QuizLevelOne .accordion-button {
  font-size: 13px;
  background: #faf9f3;
}

@media only screen and (max-width: 991px) {
  .min200 {
    min-width: max-content !important;
  }
}

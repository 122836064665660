.card-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: repeat(3, 30px);
  grid-template-areas:
    'sidebar title title'
    'sidebar main1 main2'
    'sidebar main1 main2';
  font-size: 12px;
  width: 152px;
  height: 91px;
  background: #faf9f3;
  border-radius: 5px;
  box-shadow: 0px 5px 15px rgba(208, 205, 187, 0.6);
  margin: 10px 0;
}
.icon-section {
  grid-area: sidebar;
  grid-column-start: 1;
  grid-column-end: 2;
  margin: 0;
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.icon-info {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  margin: 5px 0;
  width: 15px;
  height: auto;
}
.icon-graph {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
  margin: 5px 0;
  width: 15px;
  height: auto;
}
.icon-running {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 3;
  grid-row-end: 4;
  margin: 5px 0;
  width: 15px;
  height: auto;
}

.card-main {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  margin: 0;
  padding: 0;
}

.user-name {
  padding: 0;
  margin: 0;
  grid-area: title;
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 2;
  grid-column-end: 4;
  margin-top: 12px;
}
.user-name p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90px;
}
.user-picture .user-plans {
  padding: 0;
  margin: 15px 20px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #dccab6;
}
.user-picture {
  grid-area: main1;
}
.user-plans {
  grid-area: main2;
  margin: 0 15px;
}

.addNewCategoryWrapper {
  input {
    background-color: #fffefc;
    border: 1px solid #ebe9dd;
    box-sizing: border-box;
    border-radius: 5px;
    color: #4c443f;
    font-size: 12px;
    line-height: 21px;
    padding: 15px 10px;
  }
  .SaveBtn {
    label {
      font-size: 10px !important;
    }
  }
}

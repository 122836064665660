.secondaryText {
  font-size: 10px;
  line-height: 11px;
  text-align: right;
  text-transform: uppercase;
  color: #62a8a4;
  .addLink {
    cursor: pointer;
  }
}
.exerciseActionWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.exerciseLibrary {
  .exerciseLevelOne {
    padding: 4px;
    background: #f4f3ee;
    display: flex;
    border-top: 1px solid #ebe9dd;
    border-bottom: 1px solid #ebe9dd;
    justify-content: space-between;
    padding-right: 40px;
    align-items: center;
    font-size: 12px;
    line-height: 15px;
    color: #cebda9;

    .accordion-button {
      background: #f4f3ee;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #cebda9;
    }
  }
}

.nutritionFormWrapper {
  padding: 20px;
  label {
    font-size: 11px;
    line-height: 12px;
    color: #4c443f;
  }
}
.nutritionTableWrapper {
  border-top: 1px solid #ebe9dd;
  .workoutTableTitle {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: #aa957f;
    border-bottom: 1px solid #ebe9dd;
    td {
      padding: 10px;
    }
    td:nth-child(1) {
      padding-left: 20px;
    }
  }
}

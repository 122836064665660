.nutritionLevelWrapper {
  background: #faf9f3;
  .nutritionAccordion {
    .accordionWorkout {
      background: #fffefc;
      display: flex;
      align-items: center;
      padding: 0 16px;
      .accordion-button {
        font-size: 12px !important;
        line-height: 15px;
        color: #4c443f !important;
        text-transform: inherit;
      }
    }
  }
}

.commonlyUsed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background: #faf9f3;
  border-top: 1px solid #e8e6e4;
  p {
    font-size: 12px;
    line-height: 15px;
    color: #4c443f;
    padding-left: 15px;
  }
  hr {
    width: 60%;
  }
}

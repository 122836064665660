.activeList {
  background: #4c443f !important;
  .userMessageWrapper {
    p {
      color: #fff !important;
    }
  }
}
.listWrapper {
  background: #faf9f3;
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px;
  border-top: 2px solid #f4f3ee;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    background: #e3e2da;
  }
  .userImgWrapper {
    padding: 0 5px;

    img {
      object-fit: cover;
      border-radius: 50%;
    }
  }
  .userMessageWrapper {
    p {
      margin: 0;
      font-weight: bold;
      color: #4c443f;
      padding: 0 20px 0 0;
    }
    .nameUser {
      font-size: 10px;
      line-height: 12px;
    }
    .messageUser {
      font-size: 12px;
      line-height: 15px;
    }
  }
  .userActionIcon {
    .menuDots {
      top: 22px;
      right: 5px;
    }
  }
}

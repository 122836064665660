.actionTab {
  font-size: 9px;
  line-height: 11px;
  text-transform: uppercase;
  color: #62a8a4;
  .addLevel {
  }
  .deleteLevel {
    opacity: 0.5;
  }
}

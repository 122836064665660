.momentsLibraryWrapper {
  .morningMomentsLibrary {
    position: relative;
    background: #faf9f3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 40px 10px 20px;
    border-top: 1px solid #e8e6e4;
    img {
      width: 57px;
    }
    p {
      font-size: 12px;
      line-height: 15px;
      color: #4c443f;
    }
    .menuDots {
      top: 12px;
    }
  }
}

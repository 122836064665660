.inputTitle {
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  color: #aa957f;
  width: max-content;
  white-space: nowrap;
}

.messages-container {
  font-family: Montserrat, serif;
  font-style: normal;
  display: inline;
  background-color: #4c443f;
  border-radius: 5px;
  width: 100%;
  display: grid;
  right: 0;
  color: #f4f3ee;
}

.messages-subcontainer {
  padding: 10px;
}
.message-title {
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.recent-messages {
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
}
.dot-position {
  margin: 0 6px;
}
.all-messages {
  font-weight: 600;
  font-size: 9px;
  line-height: 11px;
  text-align: right;
  text-transform: uppercase;

  color: #c7922c;
}
.menu-messages {
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
}

.name-messages {
  font-weight: 700;
  font-size: 10px;
  line-height: 12.19px;
}

.message {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
}

.exercise-message {
  font-family: Montserrat, serif;
  font-style: italic;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
}
.Carousel-indicators-2 {
  position: absolute;
  width: 80px !important;
  top: -31px !important;
  left: 50%;
  margin-left: -40px;
}
.rectangles img {
  width: 89%;
  padding-right: 5px;
}
.overFlowView {
  overflow: inherit !important;
}
.main-message {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  padding: 8px 10px 5px;
  align-items: center;
}
.main-message p {
  margin-bottom: 0;
}
.profile-message {
  margin: 10px;
}

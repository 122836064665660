.bgWhite {
  background: #fffefc;
  width: 100%;
  margin: 0 auto;

  .allUsersList {
    .userBox {
      background: #f4f3ee;
      border-radius: 5px;
      display: flex;
      padding: 6px 10px;
      align-items: center;
      margin: 5px 0;
      .userImages {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 50%;
      }
      .userName {
        font-size: 12px;
        line-height: 15px;
        color: #4c443f;
        padding-left: 5px;
      }
    }
  }
}

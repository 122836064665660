.community-chat {
  height: calc(100vh - 167px);
  overflow: hidden;

  & > div {
    height: 100%;
  }
}

.userProfileRightContent {
  width: 100%;
  background-color: #fffefc;
  height: 100%;
  display: flex;
  flex-direction: column;

  .nav {
    flex-wrap: nowrap !important;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .nav-item:not(:last-child) {
    border-right: 2px solid #e8e6e4;
  }

  .nav-item {
    background-color: #fffefc;
  }

  .tab-content {
    height: calc(100% - 50px);

    & > div {
      height: 100%;

      & > .container {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
  }

  .tabList .nav-link {
    font-style: normal;
    font-weight: bold;
    font-size: 9px !important;
    line-height: 11px !important;
    text-align: center;
    text-transform: uppercase;
    color: #62a8a4 !important;
    padding: 15px 24px 14px;
    border: none;
    white-space: nowrap !important;
  }

  .noDataWrapper {
    text-align: center;
    padding: 10px;
    background: #fffefc;

    .noDataText {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      color: #aa957f;
    }
  }
}

.container-subNav {
  display: inline;
  width: 950px;
  height: 140px;
  left: 250px;
  top: 110px;
  background: #faf9f3;
  border-radius: 5px;
  box-shadow: 0px 5px 15px rgba(208, 205, 187, 0.6);
}
.subcontainer-subNav {
  margin: 0;
  padding: 25px;
  font-family: Montserrat, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  align-content: stretch;
  flex-wrap: wrap;
  list-style: none;
  /* identical to box height */
  background: #faf9f3;
  border-radius: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.subcontainer-subNav li:nth-child(2n -1) {
  min-width: 87px;
}
.main {
  color: #4c443f;
}
.quizz {
  color: #cebda9;
}

.goals {
  color: #dbb993;
}

.movement {
  color: #e7a053;
}

.subcard {
  display: flex;
  justify-content: flex-start;
}
.icon-subcard {
  margin: 10px;
}
.activity {
  color: #cebda9;
}
.activity-wrap {
  align-content: center;
  justify-content: center;
  width: 66px;
  height: 24px;
}
.ellipse {
  position: relative;
  bottom: 60px;
  right: 12px;
}
.activity-text {
  display: flex;
  flex-direction: column;
}
